import {Component, ElementRef, QueryList, ViewChildren} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {NavigationEntry} from '../shared/models/navbar/navigation-entry.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  @ViewChildren('navbarlink', {read: ElementRef}) navbarLinks: QueryList<ElementRef>;

  navigationEntries: NavigationEntry[] = NavigationEntry.getNavigationEntriesForNavigationBar();
  currentNavigationLink: string;

  constructor(private router: Router, private keycloakService: KeycloakService) {
    this.currentNavigationLink = this.router.url;
    router.events.subscribe((event) => this.onRouteChange(event));
  }

  private onRouteChange(event) {
    if (event instanceof NavigationStart) {
      this.currentNavigationLink = event.url;
    }
  }

  public logout() {
    this.keycloakService.logout();
  }
}
