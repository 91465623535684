import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FeedbackViewComponent} from './views/feedback-view/feedback-view.component';
import {AppAuthGuard} from './utils/app-auth-guard';
import {EmailListRecipientViewComponent} from './views/email-list-recipient-view/email-list-recipient-view.component';
import {StartViewComponent} from './views/start-view/start-view.component';
import {HtmlEmailViewComponent} from './views/html-email-view/html-email-view.component';
import {EmailEditorViewComponent} from './views/email-editor-view/email-editor-view.component';
import {SubscriberEmailViewComponent} from "./views/subscriber-email-view/subscriber-email-view.component";

const routes: Routes = [
  {
    path: '',
    component: StartViewComponent,
    canActivate: [AppAuthGuard],
    data: {roles: ['admin']}
  },
  {
    path: 'feedback',
    component: FeedbackViewComponent,
    canActivate: [AppAuthGuard],
    data: {roles: ['admin']}
  },
  {
    path: 'email-list-recipient',
    component: EmailListRecipientViewComponent,
    canActivate: [AppAuthGuard],
    data: {roles: ['admin']}
  },
  {
    path: 'html-email',
    component: HtmlEmailViewComponent,
    canActivate: [AppAuthGuard],
    data: {roles: ['admin']}
  },
  {
    path: 'subscriber-email',
    component: SubscriberEmailViewComponent,
    canActivate: [AppAuthGuard],
    data: {roles: ['admin']}
  },
  {
    path: 'email-editor/:type/:id',
    component: EmailEditorViewComponent,
    canActivate: [AppAuthGuard],
    data: {roles: ['admin']}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
  providers: [AppAuthGuard]
})
export class AppRoutingModule {


}
