import {Component, OnInit} from '@angular/core';
import {EmailListRecipientApiService} from '../../services/api/email-list-recipient/email-list-recipient-api.service';
import {EmailListRecipientViewModel} from '../../shared/models/email-list-recipient/email-list-recipient.view-model';

@Component({
  selector: 'app-email-list-recipient-view',
  templateUrl: './email-list-recipient-view.component.html',
  styleUrls: ['./email-list-recipient-view.component.css']
})
export class EmailListRecipientViewComponent implements OnInit {

  emailListRecipientViewModels: EmailListRecipientViewModel[];

  constructor(private emailListRecipientApiService: EmailListRecipientApiService) {
  }

  ngOnInit(): void {
    this.loadEntries();
  }

  private loadEntries() {
    this.emailListRecipientApiService.loadAll().subscribe(emailListRecipientList => {
      this.emailListRecipientViewModels = emailListRecipientList;
    });
  }

  delete(id: number) {
    this.emailListRecipientApiService.delete(id).subscribe(() => this.loadEntries());
  }
}
