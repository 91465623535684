import {KeycloakConfig} from 'keycloak-angular';

const keycloakConfig: KeycloakConfig = {
  clientId: 'produktive-admin-ui',
  credentials: undefined,
  url: 'https://keycloak.produktivkeller.com/auth',
  realm: 'PROD'
};

export const environment = {
  name: 'PROD',
  production: false,
  baseUrl: 'https://produktivkeller.com/api',
  keycloak: keycloakConfig
};
