import {Component, OnInit} from '@angular/core';
import {FeedbackViewModel} from '../../shared/models/feedback/feedback.view-model';
import {FeedbackApiService} from '../../services/api/feedback/feedback-api.service';

@Component({
  selector: 'app-feedback-view',
  templateUrl: './feedback-view.component.html',
  styleUrls: ['./feedback-view.component.scss']
})
export class FeedbackViewComponent implements OnInit {

  feedbackViewModels: FeedbackViewModel[];

  constructor(private feedbackApiService: FeedbackApiService) {
  }


  ngOnInit(): void {

    this.feedbackApiService.loadAll().subscribe(feedbackList => {
      this.feedbackViewModels = feedbackList;
    });
  }
}
