import {Component, OnInit} from '@angular/core';
import {SubscriberEmailApiService} from '../../services/api/subscriber-email/subscriber-email-api.service';
import {SubscriberEmailViewModel} from '../../shared/models/subscriber-email/subscriber-email.view-model';

@Component({
  selector: 'app-subscriber-email-view',
  templateUrl: './subscriber-email-view.component.html',
  styleUrls: ['./subscriber-email-view.component.scss']
})
export class SubscriberEmailViewComponent implements OnInit {

  constructor(private subscriberEmailApiService: SubscriberEmailApiService) {
  }

  subscriberEmailViewModels: SubscriberEmailViewModel[];

  ngOnInit(): void {
    this.loadEmailTemplates();
  }

  private loadEmailTemplates() {
    this.subscriberEmailApiService
      .loadAll()
      .subscribe(subscriberEmailViewModels => {
        this.subscriberEmailViewModels = subscriberEmailViewModels;
      });
  }
}
