import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {FeedbackViewModel} from '../../../shared/models/feedback/feedback.view-model';

@Injectable({
  providedIn: 'root'
})
export class FeedbackApiService {

  private url = 'admin/feedback';

  constructor(private httpClient: HttpClient) {
  }

  loadAll(): Observable<FeedbackViewModel[]> {
    return this.httpClient
      .get(this.url)
      .pipe(
        map((data: any[]) =>
          data.map(
            (item: any) =>
              new FeedbackViewModel(
                item.id,
                item.concept,
                item.graphics,
                item.sound,
                item.gameplay,
                item.text,
                new Date(item.createdAt)
              )
          )
        )
      );
  }
}
