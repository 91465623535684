export class NavigationEntry {

  public routerLink: string;
  public name: string;

  constructor(routerLink: string, name: string) {
    this.routerLink = routerLink;
    this.name = name;
  }

  public static getNavigationEntriesForNavigationBar(): NavigationEntry[] {

    return NavigationEntry.getAllNavigationEntries();
  }

  private static getAllNavigationEntries(): NavigationEntry[] {

    return [
      new NavigationEntry('/feedback', 'Feedback'),
      new NavigationEntry('/email-list-recipient', 'Email-Liste'),
      new NavigationEntry('/html-email', 'HTML-Email'),
      new NavigationEntry('/subscriber-email', 'Subscriber-Email')
    ];
  }
}
