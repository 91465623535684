import {Component, OnInit, ViewChild} from '@angular/core';
import {EmailEditorComponent} from 'angular-email-editor';
import {ActivatedRoute} from '@angular/router';
import {HtmlEmailApiService} from '../../services/api/html-email/html-email-api.service';
import {HtmlEmailModifyModel} from '../../shared/models/html-email/html-email.modify-model';
import {SubscriberEmailApiService} from '../../services/api/subscriber-email/subscriber-email-api.service';
import {SubscriberEmailModifyModel} from '../../shared/models/subscriber-email/subscriber-email.modify-model';

@Component({
  selector: 'app-email-editor-view',
  templateUrl: './email-editor-view.component.html',
  styleUrls: ['./email-editor-view.component.scss']
})
export class EmailEditorViewComponent implements OnInit {

  jsonTemplate: string;
  jsonTemplateToLoad: string;
  name: string;
  id: number;
  type: 'html-email' | 'subscriber-email';

  options = {};

  private editorLoaded = false;

  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;

  constructor(
    private htmlEmailApiService: HtmlEmailApiService,
    private subscriberEmailApiService: SubscriberEmailApiService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {

      this.type = params.type;
      this.id = +params.id;

      if (this.type === 'html-email') {
        this.loadHtmlEmailWithId(this.id);

      } else if (this.type === 'subscriber-email') {
        this.loadSubscriberEmailWithId(this.id);
      }
    });
  }

  private loadHtmlEmailWithId(id: number) {
    this.htmlEmailApiService.load(id).subscribe(result => {

      this.jsonTemplate = result.jsonTemplate;
      this.name = result.name;

      if (this.editorLoaded) {
        this.applyTemplate();
      }
    });
  }

  private loadSubscriberEmailWithId(id: number) {
    this.subscriberEmailApiService.load(id).subscribe(result => {

      this.jsonTemplate = result.jsonTemplate;
      this.name = result.name;

      if (this.editorLoaded) {
        this.applyTemplate();
      }
    });
  }

  onEditorLoaded() {
    this.editorLoaded = true;

    if (this.jsonTemplate) {
      this.applyTemplate();
    }
  }

  saveDesign() {
    this.emailEditor.editor.saveDesign(
      (data) => console.log('saveDesign', data)
    );
  }

  applyTemplate() {
    this.emailEditor.editor.loadDesign(JSON.parse(this.jsonTemplate));
  }

  updateHtmlEmail() {
    this.emailEditor.editor.saveDesign(
      (design) => {

        const jsonTemplate = JSON.stringify(design);

        if (this.type === 'html-email') {

          const htmlEmailModifyModel = new HtmlEmailModifyModel(this.name, jsonTemplate);
          this.htmlEmailApiService.modify(this.id, htmlEmailModifyModel).subscribe();

        } else if (this.type === 'subscriber-email') {

          this.emailEditor.editor.exportHtml((data) => {

            const subscriberEmailModifyModel = new SubscriberEmailModifyModel(data.html, jsonTemplate);
            this.subscriberEmailApiService.modify(this.id, subscriberEmailModifyModel).subscribe();
          });
        }
      }
    );
  }

  loadJsonTemplate() {
    this.emailEditor.editor.loadDesign(JSON.parse(this.jsonTemplateToLoad));
  }
}
