export class FeedbackViewModel {

  constructor(
    public id: number,
    public concept: number,
    public graphics: number,
    public sound: number,
    public gameplay: number,
    public text: string,
    public createdAt: Date
  ) {
  }
}
