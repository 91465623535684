import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';
import {HtmlEmailViewModel} from '../../shared/models/html-email/html-email.view-model';
import {EmailTransmissionCreateModel} from '../../shared/models/email-transmission/email-transmission.create-model';
import {EmailEditorComponent} from 'angular-email-editor';
import {EmailListRecipientViewModel} from '../../shared/models/email-list-recipient/email-list-recipient.view-model';

@Component({
  selector: 'app-email-transmission-modal',
  templateUrl: './email-transmission-modal.component.html',
  styleUrls: ['./email-transmission-modal.component.scss']
})
export class EmailTransmissionModalComponent implements OnInit {

  emailListRecipientViewModels: EmailListRecipientViewModel[];
  htmlEmailViewModel: HtmlEmailViewModel;
  confirmation: boolean;
  subject: string;
  toEmailList = false;
  bcc: string;
  emailTransmissionCreateModel: EmailTransmissionCreateModel;
  options = {};

  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;
  private editorLoaded = false;

  constructor(public bsModalRef: BsModalRef) {

  }

  ngOnInit(): void {
  }

  send() {

    this.emailEditor.editor.exportHtml((data) => {

      const bcc = this.toEmailList ?
        this.emailListRecipientViewModels.map(recipient => recipient.email) :
        this.bcc.split(';').map(element => element.trim());

      this.emailTransmissionCreateModel = {
        htmlEmailId: this.htmlEmailViewModel.id,
        subject: this.subject,
        body: data.html,
        bcc
      };

      this.confirmation = true;
      this.bsModalRef.hide();
    });
  }

  onEditorLoaded() {
    this.editorLoaded = true;

    if (this.htmlEmailViewModel) {
      this.applyTemplate();
    }
  }

  applyTemplate() {
    this.emailEditor.editor.loadDesign(JSON.parse(this.htmlEmailViewModel.jsonTemplate));
  }

  isBlank(str: string) {
    return (!str || /^\s*$/.test(str));
  }

}
