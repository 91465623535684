import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SubscriberEmailViewModel} from '../../../shared/models/subscriber-email/subscriber-email.view-model';
import {SubscriberEmailModifyModel} from '../../../shared/models/subscriber-email/subscriber-email.modify-model';

@Injectable({
  providedIn: 'root'
})
export class SubscriberEmailApiService {

  private url = 'admin/subscriber-email';

  constructor(private httpClient: HttpClient) {
  }

  modify(id: number, subscriberEmailModifyModel: SubscriberEmailModifyModel) {
    return this.httpClient
      .put(this.url + '/' + id, subscriberEmailModifyModel);
  }

  load(id: number): Observable<SubscriberEmailViewModel> {
    return this.httpClient
      .get(this.url + '/' + id)
      .pipe(
        map((item: any) =>
          new SubscriberEmailViewModel(
            item.id,
            item.name,
            item.jsonTemplate,
            item.html,
            item.lastModified ? new Date(item.lastModified) : null,
            item.createdAt ? new Date(item.createdAt) : null
          )
        )
      );
  }

  loadAll(): Observable<SubscriberEmailViewModel[]> {
    return this.httpClient
      .get(this.url)
      .pipe(
        map((data: any[]) =>
          data.map(
            (item: any) =>
              new SubscriberEmailViewModel(
                item.id,
                item.name,
                item.jsonTemplate,
                item.html,
                item.lastModified ? new Date(item.lastModified) : null,
                item.createdAt ? new Date(item.createdAt) : null
              )
          )
        )
      );
  }
}
