import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EmailListRecipientViewModel} from '../../../shared/models/email-list-recipient/email-list-recipient.view-model';

@Injectable({
  providedIn: 'root'
})
export class EmailListRecipientApiService {

  private url = 'admin/email-list-recipient';

  constructor(private httpClient: HttpClient) {
  }

  loadAll(): Observable<EmailListRecipientViewModel[]> {
    return this.httpClient
      .get(this.url)
      .pipe(
        map((data: any[]) =>
          data.map(
            (item: any) =>
              new EmailListRecipientViewModel(
                item.id,
                item.email,
                new Date(item.createdAt),
                item.language,
                item.activationCode,
                item.activated
              )
          )
        )
      );
  }

  delete(id: number) {
    return this.httpClient
      .delete(this.url + '/' + id);
  }
}
