<div id="sub-nav">
  <input [(ngModel)]="name" type="text" class="form-control" placeholder="Template Name" [disabled]="type === 'subscriber-email'">
  <button class="btn btn-primary" (click)="updateHtmlEmail()">
    <span class="fa fa-save fa-lg"></span>
  </button>
  <input [(ngModel)]="jsonTemplateToLoad" type="text" class="form-control" placeholder="JSON-Template laden">
  <button class="btn btn-primary" (click)="loadJsonTemplate()">
    <span class="fa fa-upload fa-lg"></span>
  </button>
</div>

<email-editor
  [options]="options"
  (loaded)="onEditorLoaded()"
  #editor
></email-editor>
