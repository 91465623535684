
<table class="table">
  <thead>
  <tr>
    <th>ID</th>
    <th>Erstellt Am</th>
    <th>Email</th>
    <th>Sprache</th>
    <th>Aktiviert</th>
    <th>Aktivierungs-Code</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let emailListRecipient of emailListRecipientViewModels">
    <td>{{emailListRecipient.id}}</td>
    <td>{{emailListRecipient.createdAt | date:'short' }}</td>
    <td>{{emailListRecipient.email}}</td>
    <td>{{emailListRecipient.language}}</td>
    <td>{{emailListRecipient.activated}}</td>
    <td>{{emailListRecipient.activationCode}}</td>
    <td>
      <button class="btn btn-danger" (click)="delete(emailListRecipient.id)">Delete</button>
    </td>
  </tr>
  </tbody>
</table>
