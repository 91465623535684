<div class="modal-header">
  <h4 class="modal-title pull-left">Email</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <h3>{{htmlEmailViewModel.name}}</h3>

  <div class="form-group">
    <label for="subject">Betreff</label>
    <input [(ngModel)]="subject" type="text" class="form-control" id="subject" placeholder="Subject">
  </div>

  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="toEmailList" [(ngModel)]="toEmailList">
    <label class="form-check-label" for="toEmailList">An Email-Liste</label>
  </div>

  <div class="form-group" *ngIf="!toEmailList">
    <label for="bcc">Empfänger</label>
    <textarea  [(ngModel)]="bcc" type="text" class="form-control" id="bcc" placeholder="test@foobar.de; bla@bla.de"></textarea>
  </div>

  <email-editor
    [options]="options"
    (loaded)="onEditorLoaded()"
    #editor
  ></email-editor>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="send()" [disabled]="isBlank(subject) || (isBlank(bcc) && !toEmailList)">Abschicken</button>
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Schließen</button>
</div>
