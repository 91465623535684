<div class="modal-header">
  <h4 class="modal-title pull-left">Bist du sicher?</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <p>Willst du den Eintrag wirklich löschen?</p>

  <table class="table table-striped">
    <thead></thead>
    <tbody>
    <tr>
      <td>ID</td>
      <td>{{deleteObject.id ? deleteObject.id : '-'}}</td>
    </tr>
    <tr>
      <td>Name</td>
      <td>{{deleteObject.name ? deleteObject.name : '-'}}</td>
    </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="delete()" >Löschen</button>
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Schließen</button>
</div>
