export class HtmlEmailViewModel {

  constructor(
    public id: number,
    public name: string,
    public jsonTemplate: string,
    public bcc: string,
    public sentAt: Date,
    public lastModified: Date,
    public createdAt: Date,
  ) {
  }
}
