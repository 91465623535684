
<table class="table">
  <thead>
  <tr>
    <th>ID</th>
    <th>Konzept</th>
    <th>Grafik</th>
    <th>Sound</th>
    <th>Gameplay</th>
    <th>Erstellt Am</th>
    <th>Text</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let feedback of feedbackViewModels">
    <td>{{feedback.id}}</td>
    <td>{{feedback.concept}}</td>
    <td>{{feedback.graphics}}</td>
    <td>{{feedback.sound}}</td>
    <td>{{feedback.gameplay}}</td>
    <td>{{feedback.createdAt | date:'short' }}</td>
    <td>{{feedback.text}}</td>
  </tr>
  </tbody>
</table>
