import {Component} from '@angular/core';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'produktive-admin-ui';

  onActivate($event: any) {

    window.document.title = `${environment.name} Admin Panel`;

    window.scroll(0, 0);
  }
}
