
<table class="table">
  <thead>
  <tr>
    <th>ID</th>
    <th>Name</th>
    <th>JSON-Template</th>
    <th>BCC</th>
    <th>Gesendet Am</th>
    <th>Erstellt Am</th>
    <th>Geändert Am</th>
    <th>Aktionen</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let htmlEmailViewModel of htmlEmailViewModels">
    <td>{{htmlEmailViewModel.id}}</td>
    <td>{{htmlEmailViewModel.name}}</td>
    <td>
      <button *ngIf="htmlEmailViewModel.jsonTemplate" ngxClipboard [cbContent]="htmlEmailViewModel.jsonTemplate" type="button" class="btn btn-primary" title="{{htmlEmailViewModel.jsonTemplate}}">
        <span class="fa fa-clipboard fa-lg" aria-hidden="true"></span>
      </button>
      <span *ngIf="!htmlEmailViewModel.jsonTemplate">-</span>
    </td>
    <td>
      <button *ngIf="htmlEmailViewModel.bcc" ngxClipboard [cbContent]="htmlEmailViewModel.bcc" type="button" class="btn btn-primary" title="{{htmlEmailViewModel.bcc}}">
        <span class="fa fa-clipboard fa-lg" aria-hidden="true"></span>
      </button>
      <span *ngIf="!htmlEmailViewModel.bcc">-</span>
    <td>{{htmlEmailViewModel.sentAt | date:'short' }}</td>
    <td>{{htmlEmailViewModel.createdAt | date:'short' }}</td>
    <td>{{htmlEmailViewModel.lastModified | date:'short' }}</td>
    <td class="table-button-group">
      <button class="btn btn-primary" (click)="duplicateHtmlEmail(htmlEmailViewModel)">
        <span class="fa fa-copy"></span>
      </button>
      <button class="btn btn-primary" [routerLink]="['/email-editor', 'html-email', htmlEmailViewModel.id]" [disabled]="htmlEmailViewModel.sentAt">
        <span class="fa fa-pen"></span>
      </button>
      <button class="btn btn-primary" (click)="send(htmlEmailViewModel)" [disabled]="htmlEmailViewModel.sentAt">
        <span class="fa fa-paper-plane"></span>
      </button>
      <button class="btn btn-danger" (click)="delete(htmlEmailViewModel)">
        <span class="fa fa-trash"></span>
      </button>
    </td>
  </tr>
  </tbody>
</table>
