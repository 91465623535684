import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {APIInterceptor} from './services/api-interceptor/api-interceptor';
import {AppComponent} from './app.component';
import {FeedbackViewComponent} from './views/feedback-view/feedback-view.component';
import {NavbarComponent} from './navbar/navbar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {initializer} from './utils/app-init';
import {EmailListRecipientViewComponent} from './views/email-list-recipient-view/email-list-recipient-view.component';
import {EmailEditorModule} from 'angular-email-editor';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {StartViewComponent} from './views/start-view/start-view.component';
import {HtmlEmailViewComponent} from './views/html-email-view/html-email-view.component';
import {ClipboardModule} from 'ngx-clipboard';
import {ModalModule} from 'ngx-bootstrap';
import {DeleteModalComponent} from './modals/delete-modal/delete-modal.component';
import {EmailEditorViewComponent} from './views/email-editor-view/email-editor-view.component';
import {EmailTransmissionModalComponent} from './modals/email-transmission-modal/email-transmission-modal.component';
import {SubscriberEmailViewComponent} from "./views/subscriber-email-view/subscriber-email-view.component";

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    FeedbackViewComponent,
    NavbarComponent,
    EmailListRecipientViewComponent,
    StartViewComponent,
    HtmlEmailViewComponent,
    DeleteModalComponent,
    EmailEditorViewComponent,
    EmailTransmissionModalComponent,
    SubscriberEmailViewComponent
  ],
  imports: [
    KeycloakAngularModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    EmailEditorModule,
    AutoCompleteModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    ClipboardModule,
    ModalModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
