import {Component, OnInit} from '@angular/core';
import {HtmlEmailApiService} from '../../services/api/html-email/html-email-api.service';
import {HtmlEmailViewModel} from '../../shared/models/html-email/html-email.view-model';
import {HtmlEmailCreateModel} from '../../shared/models/html-email/html-email.create-model';
import {DeleteModalComponent} from '../../modals/delete-modal/delete-modal.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {EmailTransmissionModalComponent} from '../../modals/email-transmission-modal/email-transmission-modal.component';
import {EmailTransmissionApiService} from '../../services/api/email-transmission/email-transmission-api.service';
import {EmailListRecipientViewModel} from '../../shared/models/email-list-recipient/email-list-recipient.view-model';
import {EmailListRecipientApiService} from '../../services/api/email-list-recipient/email-list-recipient-api.service';

@Component({
  selector: 'app-html-email-view',
  templateUrl: './html-email-view.component.html',
  styleUrls: ['./html-email-view.component.scss']
})
export class HtmlEmailViewComponent implements OnInit {

  constructor(
    private htmlEmailApiService: HtmlEmailApiService,
    private modalService: BsModalService,
    private emailTransmissionApiService: EmailTransmissionApiService,
    private emailListRecipientApiService: EmailListRecipientApiService
  ) {
  }

  emailListRecipientViewModels: EmailListRecipientViewModel[];
  deleteModal: BsModalRef;
  emailTransmissionModal: BsModalRef;
  htmlEmailViewModels: HtmlEmailViewModel[];

  private static randomString() {

    const chars = 'abcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 3; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  ngOnInit(): void {

    this.emailListRecipientApiService
      .loadAll()
      .subscribe(result => this.emailListRecipientViewModels = result);

    this.modalService.onHide.subscribe(_ => {

      if (this.deleteModal && this.deleteModal.content.confirmation) {

        this.htmlEmailApiService
          .delete(this.deleteModal.content.deleteObject.id)
          .subscribe(() => this.loadEmailTemplates());

        this.deleteModal = null;

      } else if (this.emailTransmissionModal && this.emailTransmissionModal.content.confirmation) {

        this.emailTransmissionApiService
          .create(this.emailTransmissionModal.content.emailTransmissionCreateModel)
          .subscribe(() => this.loadEmailTemplates());

        this.emailTransmissionModal = null;
      }
    });

    this.loadEmailTemplates();
  }

  private loadEmailTemplates() {
    this.htmlEmailApiService
      .loadAll()
      .subscribe(emailTemplates => {
        this.htmlEmailViewModels = emailTemplates;
      });
  }

  duplicateHtmlEmail(htmlEmailViewModel: HtmlEmailViewModel) {

    this.htmlEmailApiService.create(new HtmlEmailCreateModel(
      htmlEmailViewModel.name + ' ' + HtmlEmailViewComponent.randomString(),
      htmlEmailViewModel.jsonTemplate
    )).subscribe(() => this.loadEmailTemplates());
  }

  delete(htmlEmailViewModel: HtmlEmailViewModel) {

    const initialState = {
      deleteObject: htmlEmailViewModel
    };

    this.deleteModal = this.modalService.show(DeleteModalComponent, {initialState});
  }

  send(htmlEmailViewModel: HtmlEmailViewModel) {

    const initialState = {
      emailListRecipientViewModels: this.emailListRecipientViewModels,
      htmlEmailViewModel
    };

    this.emailTransmissionModal = this.modalService.show(EmailTransmissionModalComponent, {initialState});
  }
}
