import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HtmlEmailCreateModel} from '../../../shared/models/html-email/html-email.create-model';
import {HtmlEmailModifyModel} from '../../../shared/models/html-email/html-email.modify-model';
import {HtmlEmailViewModel} from '../../../shared/models/html-email/html-email.view-model';

@Injectable({
  providedIn: 'root'
})
export class HtmlEmailApiService {

  private url = 'admin/html-email';

  constructor(private httpClient: HttpClient) {
  }

  create(htmlEmailCreateModel: HtmlEmailCreateModel) {
    return this.httpClient
      .post(this.url, htmlEmailCreateModel);
  }

  modify(id: number, htmlEmailModifyModel: HtmlEmailModifyModel) {
    return this.httpClient
      .put(this.url + '/' + id, htmlEmailModifyModel);
  }

  delete(id: number) {
    return this.httpClient
      .delete(this.url + '/' + id);
  }

  load(id: number): Observable<HtmlEmailViewModel> {
    return this.httpClient
      .get(this.url + '/' + id)
      .pipe(
        map((item: any) =>
          new HtmlEmailViewModel(
            item.id,
            item.name,
            item.jsonTemplate,
            item.bcc,
            item.sentAt ? new Date(item.sentAt) : null,
            item.lastModified ? new Date(item.lastModified) : null,
            item.createdAt ? new Date(item.createdAt) : null
          )
        )
      );
  }

  loadAll(): Observable<HtmlEmailViewModel[]> {
    return this.httpClient
      .get(this.url)
      .pipe(
        map((data: any[]) =>
          data.map(
            (item: any) =>
              new HtmlEmailViewModel(
                item.id,
                item.name,
                item.jsonTemplate,
                item.bcc,
                item.sentAt ? new Date(item.sentAt) : null,
                item.lastModified ? new Date(item.lastModified) : null,
                item.createdAt ? new Date(item.createdAt) : null
              )
          )
        )
      );
  }
}
