import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  deleteObject: any;
  confirmation: boolean;

  constructor(public bsModalRef: BsModalRef) {

  }

  ngOnInit(): void {
  }

  delete() {
    this.confirmation = true;
    this.bsModalRef.hide();
  }
}
