
<table class="table">
  <thead>
  <tr>
    <th>ID</th>
    <th>Name</th>
    <th>HTML</th>
    <th>JSON-Template</th>
    <th>Erstellt Am</th>
    <th>Geändert Am</th>
    <th>Aktionen</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let subscriberEmailViewModel of subscriberEmailViewModels">
    <td>{{subscriberEmailViewModel.id}}</td>
    <td>{{subscriberEmailViewModel.name}}</td>
    <td>
      <button *ngIf="subscriberEmailViewModel.html" ngxClipboard [cbContent]="subscriberEmailViewModel.html" type="button" class="btn btn-primary" title="{{subscriberEmailViewModel.html}}">
        <span class="fa fa-clipboard fa-lg" aria-hidden="true"></span>
      </button>
      <span *ngIf="!subscriberEmailViewModel.html">-</span>
    </td>
    <td>
      <button *ngIf="subscriberEmailViewModel.jsonTemplate" ngxClipboard [cbContent]="subscriberEmailViewModel.jsonTemplate" type="button" class="btn btn-primary" title="{{subscriberEmailViewModel.jsonTemplate}}">
        <span class="fa fa-clipboard fa-lg" aria-hidden="true"></span>
      </button>
      <span *ngIf="!subscriberEmailViewModel.jsonTemplate">-</span>
    </td>
    <td>{{subscriberEmailViewModel.createdAt | date:'short' }}</td>
    <td>{{subscriberEmailViewModel.lastModified | date:'short' }}</td>
    <td class="table-button-group">
      <button class="btn btn-primary" [routerLink]="['/email-editor', 'subscriber-email', subscriberEmailViewModel.id]">
        <span class="fa fa-pen"></span>
      </button>
    </td>
  </tr>
  </tbody>
</table>
