import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EmailTransmissionCreateModel} from '../../../shared/models/email-transmission/email-transmission.create-model';

@Injectable({
  providedIn: 'root'
})
export class EmailTransmissionApiService {

  private url = 'admin/email-transmission';

  constructor(private httpClient: HttpClient) {
  }

  create(emailTransmissionCreateModel: EmailTransmissionCreateModel) {
    return this.httpClient
      .post(this.url, emailTransmissionCreateModel);
  }
}
