export class EmailListRecipientViewModel {

  constructor(
    public id: number,
    public email: string,
    public createdAt: Date,
    public language: string,
    public activationCode: string,
    public activated: boolean
  ) {
  }
}
