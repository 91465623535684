<div class="row">
  <div class="col-12" id="wrapper">

    <img src="/assets/pics/david.png" alt="">

    <h1>A David a day keeps the developer away!</h1>
  </div>
</div>


