
<nav class="navbar navbar-dark bg-dark navbar-expand-sm">
  <div class="collapse navbar-collapse">

    <a class="navbar-brand" routerLink="/">
      <img src="/assets/pics/Produktivkeller-Icon-256x256.png" width="30" height="30" alt="">
    </a>

    <div class="navbar-nav" >
      <a *ngFor="let navigationEntry of navigationEntries"
         [class.active]="currentNavigationLink === navigationEntry.routerLink"
         class="nav-item nav-link"
         routerLink="{{navigationEntry.routerLink}}"
         #navbarlink>{{navigationEntry.name}}</a>
    </div>

    <div class="navbar-nav ml-auto">
      <a class="nav-item nav-link" (click)="logout()">
        Logout
      </a>
    </div>
  </div>
</nav>
